import React from 'react'
import Layout from '../components/layout'
import SearchInput from '../components/SearchInput'
import Seo from "../components/seo"

const Asia = () => {
    return (
        <Layout>
            <Seo title="Asia" />

            <h1 className="title">Asia</h1>

            <SearchInput />

            <table>
                <tbody>
                    <tr className="thead title">
                        <td colSpan="2">Japan</td>
                    </tr>

                    <tr>
                        <td>Platform for Patent Information (patent search)</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.j-platpat.inpit.go.jp/s0100">https://www.j-platpat.inpit.go.jp/s0100</a></td>
                    </tr>

                    <tr>
                        <td>Pharmaceutical and Medical Devices Agency (PMDA) list of approved products</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.pmda.go.jp/english/review-services/reviews/approved-information/drugs/0002.html">https://www.pmda.go.jp/english/review-services/reviews/approved-information/drugs/0002.html</a></td>
                    </tr>

                    <tr>
                        <td>Japan Platform for Patent Information</td>
                        <td><a target="_blank" rel="noreferrer" href="http://www.j-platpat.inpit.go.jp/">http://www.j-platpat.inpit.go.jp/</a></td>
                    </tr>

                    {/* Heading */}

                    <tr className="thead title">
                        <td colSpan="2">China</td>
                    </tr>

                    <tr>
                        <td>CNIPA patent search</td>
                        <td><a target="_blank" rel="noreferrer" href="http://ensearch.cnipr.com.cn/sipo_EN/">http://ensearch.cnipr.com.cn/sipo_EN/</a></td>
                    </tr>

                    <tr>
                        <td>China National Intellectual Property Administration</td>
                        <td><a target="_blank" rel="noreferrer" href="http://ensearch.cnipr.com.cn/sipo_EN/">http://ensearch.cnipr.com.cn/sipo_EN/</a></td>
                    </tr>

                    

                    <tr className="thead title">
                        <td colSpan="2">Korea</td>
                    </tr>

                    <tr>
                        <td>KIPRIS (IP search)</td>
                        <td><a target="_blank" rel="noreferrer" href="http://eng.kipris.or.kr/enghome/main.jsp">http://eng.kipris.or.kr/enghome/main.jsp</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">India</td>
                    </tr>

                    <tr>
                        <td>inPASS patent search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ipindiaservices.gov.in/PublicSearch/">https://ipindiaservices.gov.in/PublicSearch/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Malaysia</td>
                    </tr>

                    <tr>
                        <td>MyIPO online IP search and filing system</td>
                        <td><a target="_blank" rel="noreferrer" href="https://iponlineext.myipo.gov.my/SPHI/Extra/Default.aspx?sid=637817277673416481">https://iponlineext.myipo.gov.my/SPHI/Extra/Default.aspx?sid=637817277673416481</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Indonesia</td>
                    </tr>

                    <tr>
                        <td>Direketorat Jenderal Kekayaan Intelektual searching</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.dgip.go.id/">https://www.dgip.go.id/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Philippines</td>
                    </tr>

                    <tr>
                        <td>Intellectual Property Office of the Philippines</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.ipophil.gov.ph/">https://www.ipophil.gov.ph/</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Singapore</td>
                    </tr>

                    <tr>
                        <td>IPOS public search and enquiry</td>
                        <td><a target="_blank" rel="noreferrer" href="https://ip2sg.ipos.gov.sg/RPS/WP/CM/SearchSimple/IP.aspx?SearchCategory=PT">https://ip2sg.ipos.gov.sg/RPS/WP/CM/SearchSimple/IP.aspx?SearchCategory=PT</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Vietnam</td>
                    </tr>

                    <tr>
                        <td>IP Vietnam patents, designs and trademarks searches</td>
                        <td><a target="_blank" rel="noreferrer" href="http://wipopublish.ipvietnam.gov.vn/wopublish-search/public/home?1">http://wipopublish.ipvietnam.gov.vn/wopublish-search/public/home?1</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Taiwan</td>
                    </tr>

                    <tr>
                        <td>Taiwan IPO search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.tipo.gov.tw/en/lp-277-2.html">https://www.tipo.gov.tw/en/lp-277-2.html</a></td>
                    </tr>

                    <tr className="thead title">
                        <td colSpan="2">Eurasia</td>
                    </tr>

                    <tr>
                        <td>Eurasian Patent Register search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.eapo.org/en/?patents=reestr">https://www.eapo.org/en/?patents=reestr</a></td>
                    </tr>

                    <tr>
                        <td>EAPO SPC search</td>
                        <td><a target="_blank" rel="noreferrer" href="https://www.eapo.org/ru/patents/reestr/sfarma.php">https://www.eapo.org/ru/patents/reestr/sfarma.php</a></td>
                    </tr>

                </tbody>
            </table>
        </Layout>
    )
}

export default Asia